define('ember-sqwok/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('ember-sqwok');
} else {

          var exports = {
            'default': {"API_HOST":"https://staging.api.sqwok.im","API_PREFIX":"v1","APP_VERSION":"v1528","FASTBOOT_HOST_WHITELIST":"sqwokweb-ci-stagingassetsbucket-skj0qatzfahi.s3.amazonaws.com","HCAPTCHA_SITE_KEY":"10000000-ffff-ffff-ffff-000000000001","IOT_HOST":"wss://staging-stream.sqwok.im/mqtt","MEDIA_HOST":"https://staging.media.sqwok.im","MIRAGE":false,"STATIC_HOST":"https://staging.static.sqwok.im","WEB_HOST":"https://staging.web.sqwok.im","environment":"production","fastboot":{"hostWhitelist":["sqwokweb-ci-stagingassetsbucket-skj0qatzfahi.s3.amazonaws.com"]},"fontawesome":{"defaultPrefix":"far"},"locationType":"history","modulePrefix":"ember-sqwok","rootURL":"/","token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODc2MzA3OTYsIm9yaWdfaWF0IjoxNTg3NjI5ODk2LCJhdHRhY2tTaGlwc09uTWFycyI6dHJ1ZSwidGFubkhhdXNlckdhdGUiOnRydWV9.dpf5d0OKdFbas0F7IAis1ACoyikj1-LIMIfNxBFBXKo","tokenPrefix":"uskoa","ember-cli-mirage":{"enabled":false,"usingProxy":false,"useDefaultPassthroughs":true},"ember-simple-auth-token":{"authorizationPrefix":"uskoa","headers":{"authorization":"uskoa eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE1ODc2MzA3OTYsIm9yaWdfaWF0IjoxNTg3NjI5ODk2LCJhdHRhY2tTaGlwc09uTWFycyI6dHJ1ZSwidGFubkhhdXNlckdhdGUiOnRydWV9.dpf5d0OKdFbas0F7IAis1ACoyikj1-LIMIfNxBFBXKo"},"refreshLeeway":30,"refreshTokenPropertyName":"token","serverTokenEndpoint":"https://staging.api.sqwok.im/v1/auth/obtain","serverTokenRefreshEndpoint":"https://staging.api.sqwok.im/v1/auth/refresh","tokenExpirationInvalidateSession":true},"EmberENV":{"_APPLICATION_TEMPLATE_WRAPPER":false,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"ember-component-css":{"terseClassNames":true}}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
